import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
    },
    {
        path: "/detail/:id",
        name: "detail",
        component: () => import("@/components/dashboard.vue"),
        children: [
            {
                path: "3d",
                name: "3d",
                component: () => import("@/views/artifact/Exhibit/3D.vue"),
            },
            {
                path: "ar",
                name: "ar",
                component: () => import("@/views/artifact/Exhibit/AR.vue"),
            },
            {
                path: "image",
                name: "image",
                component: () => import("@/views/artifact/Exhibit/ImageLibrary.vue"),
            },
            {
                path: "movie",
                name: "movie",
                component: () => import("@/views/artifact/Exhibit/VideoLibrary.vue"),
            },
            {
                path: "game",
                name: "game",
                component: () => import("@/views/artifact/Exhibit/Game.vue"),
            },
            {
                path: "document",
                name: "document",
                component: () => import("@/views/artifact/Exhibit/Document.vue"),
            },
        ]
    }
];

const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_BASE_URL),
    routes,
});

export default router;
