import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/main.css';
import ViewUIPlus from 'view-ui-plus'
import 'view-ui-plus/dist/styles/viewuiplus.css'
import locale from 'view-ui-plus/dist/locale/vi-VN';
import VueEditor from 'vue3-editor';
import { createDeviceDetector } from "next-vue-device-detector";
export const device = createDeviceDetector()

const app = createApp(App);

app.use(ViewUIPlus, {
    locale
});

app.mixin({
    data() {
        return {
            baseImg: process.env.VUE_APP_BASE_ASSETS,
        }
    }
});


app.use(router)
    .use(VueEditor).use(device)
    .mount('#app')
