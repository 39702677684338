<template>
<router-link :to="`/detail/${id}`" @click="openDetailPage">

    <div>
      <div class="  h-[300px] dark:bg-[#1f1b24] dark:hover:bg-[#121015] dark:text-white dark:outline-none dark:border-none   relative">
        <div class="flex flex-col gap-6">
          <div>
            <img :src="img"  height="450" alt="exhibit" class="mx-auto" />
          </div>
        </div>
        <p class="text-left xl:text-lg font-[700] text-[#1A3F1E] py-5	 max-h-[96px] overflow-y-hidden  text-xl font-sans	">
            {{ desc }}
          </p>
      </div>

    </div>
</router-link>

  </template>

  <script>
  import { BASE_URL } from "@/config/url";
  export default {
    props: {
      data: {
        type: Object,
        required: true,
      },
    },
    data() {
        return {
           BASE_URL:BASE_URL
        }
    },

    computed: {
      img() {
        return `${this.BASE_URL}/avatars/${this.data.exhibitAvatar}`;
      },
      desc() {
        return this.data.exhibitTitle;
      },
      id() {
        return this.data.exhibitId;
      },
    },
    methods:{
      openDetailPage() {
      const route = this.$router.resolve({
        name: 'detail',
        params: {
          id: this.id,
        }
      });
      window.open(route.href, '_parent');
    },
    }
  };
  </script>
