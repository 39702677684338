<template>
    <div >
      <div class=" w-full min-h-fit p-10 md:p-20 grid grid-cols-1 gap-y-6 md:grid-cols-2 md:gap-x-6 lg:grid-cols-3 lg:gap-8 xl:grid-cols-4 xl:gap-10 mx-auto">
        <Card v-for="exhibit in dataExhibit" :key="exhibit.exhibitId" :data="exhibit" />
      </div>
</div>
  </template>

  <script>
  import Card from "@/components/Card.vue";
  import axios from "axios";
  import { BASE_URL } from "@/config/url";

  export default {
    data() {
      return {
        dataExhibit: [],
      };
    },
    created() {
      this.handleGetExhibit();
    },
    methods: {
      async handleGetExhibit() {
        try {
          const response = await axios.get(`${BASE_URL}/api/exhibit/all`, {
            headers:{
              "Content-Type": "application/json",
            },
          });

          this.dataExhibit = response.data.data;
        } catch (error) {
          console.log("Response data:", error.response.data);
          console.log("Response status:", error.response.status);
          console.log("Response headers:", error.response.headers);
        }
      },
    },
    components: {
      Card,
    },
  };
  </script>

