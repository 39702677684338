<template>
<div>
  <Navbar></Navbar>

  <div >
  <Carousel :autoplay="5000" :wrap-around="true" class="xl:pt-24 pt-24" >
    <Slide v-for="picture in pictures" :key="picture">
      <img class="w-100 h-100" :src="`${this.BASE_URL}/slideImages/${picture.sildeImage}`" :alt="picture.sildeImage">

    </Slide>

    <template #addons>
      <Pagination />
    </template>
  </Carousel>

  <Explore></Explore>
  <Modal class="ivu-modal-content1" v-model="showModal" width="1000" footer-hide >

<div style="padding: 20px; color: white;">
    <div style="text-align: center; font-size: 30px; padding-bottom: 10px;">
        <p style="font-weight: bold;font-family: myFont;">Lời giới thiệu</p>
    </div>
    <p style="padding-bottom: 10px; font-size: 16px;">Lịch sử quân sự Việt Nam xuất hiện ngay từ buổi đầu dựng nước và là quá trình phát triển liên tục. Trong mỗi giai đoạn, dân tộc Việt Nam luôn nỗ lực sáng tạo và giành được nhiều chiến công, lập nên những kỳ tích trong lịch sử đấu tranh chống giặc ngoại xâm và giữ vững nền độc lập của dân tộc, thể hiện nghệ thuật quân sự độc đáo để lại một di sản quân sự đồ sộ.</p>
    <p style="padding-bottom: 10px;font-size: 16px;">Bảo tàng LSQS Việt Nam là nơi lưu giữ, trưng bày và giới thiệu những hiện vật tiêu biểu trong lịch sử đấu tranh chống giặc ngoại xâm. Trong đó 12 hiện vật tiêu biểu dưới đây được ứng dụng công nghệ hiện đại, thể hiện ở nhiều góc độ, cấp độ thông tin, cách thức tìm hiểu, trải nghiệm khác nhau về những nét đặc sắc, độc đáo của từng hiện vật, nhằm tôn vinh, giới thiệu và tuyên truyền giá trị lịch sử quân sự đến đông đảo công chúng.</p>
    <p style="font-size: 16px;">Bảo tàng Lịch sử Quân sự Việt Nam trân trọng giới thiệu: Tour3D những hiện vật tiêu biểu trong lịch sử quân sự Việt Nam!</p>
</div>

<div style="text-align: center; padding: 20px;">


    <Button type="success" size="default" @click="closeModal" shape="circle">Bắt đầu tham quan</Button>
</div>

</Modal>
<Modal v-model="modalAskAudio" width="360" footer-hide >

            <div style="text-align:center;padding: 20px; color: white;">
                <p style="padding-bottom: 20px; font-size: 18px;">Kích hoạt chế độ đọc thuyết minh?</p>
                <div class="flex-row items-center">
                <Button type="none" size="large"  @click="submitAudioDenied">Không đồng ý</Button>
                <Button style="margin-left: 5px;" type="success" size="large"  @click="submitAudioAccecpt">Đồng ý</Button>
                <audio id="audio-detail" controls :src="`${baseImg}audio/Loi gioi thieu.mp3`" class="hidden"></audio>

              </div>
            </div>

            <template #footer>



            </template>
        </Modal>
</div>

<footer>
  <div class="flex xl:flex-row flex-col items-center gap-2 py-3 px-3 " style="background-image: url('/footer.png'); height: 50px;">
    <span class=" w-full  font-extrabold text-white  text-center">
      Copyright © 2023 Baotanglichsuquansu.vn All rights reserved</span>
  </div>

</footer>
</div>
</template>

<script>
import { Carousel, Pagination, Slide } from 'vue3-carousel'
import Navbar from '@/components/Navbar.vue';
import 'vue3-carousel/dist/carousel.css';
import Explore from '@/components/Explore.vue'
import  axios  from 'axios';
import { BASE_URL } from "@/config/url";

export default {
  name: 'Autoplay',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navbar,
    Explore
  },
  data() {
    return {
      showModal:false,
      modalAskAudio:true,
      BASE_URL:BASE_URL,
      hasPlayAudio:false,

    };
  },
  created() {
    this.handleSlideImage()

    const modalClosed = localStorage.getItem('modalClosed');
      if (modalClosed) {
        this.modalAskAudio=false
      }},
  methods: {
    handleOffAudio(){
          this.hasPlayAudio=false
          const fileAudioDetail = document.getElementById('audio-detail');
          fileAudioDetail.pause();
        },
        handlePlayAudio() {
          const fileAudio = document.getElementById('audio-detail');
          if (this.hasPlayAudio === false) {
            fileAudio.play();
            this.hasPlayAudio = true;
            console.log("playing");
          } else {
            fileAudio.pause();
            this.hasPlayAudio = false;
            console.log("pause");
          }
        },
    submitAudioAccecpt(){
      this.showModal=true
      this.modalAskAudio=false
      this.handlePlayAudio()
      localStorage.setItem('modalClosed', true);
    },
    submitAudioDenied(){
      this.showModal=true
      this.modalAskAudio=false
      localStorage.setItem('modalClosed', true);

    }
    ,
    closeModal() {
            this.showModal = false;
            this.handleOffAudio()
        },
    async handleSlideImage() {
        try {
          const response = await axios.get(`${BASE_URL}/api/slide_image/all`, {
            headers:{
              "Content-Type": "application/json",
            },
          });

          this.pictures = response.data.data;
        } catch (error) {

        }
      },
  },


}
</script>

<style lang="scss">
.ivu-modal-content {
    position: relative;
    background-color: #00261B95!important;
    border: 0;
    border-radius: 0px!important;
    background-clip: padding-box;
    -webkit-box-shadow: 0 4px 12px rgba(0,0,0,.15);
    box-shadow: 0 4px 12px rgba(0,0,0,.15);
}


.ivu-icon {
    display: none!important;
    font-family: Ionicons;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    text-rendering: optimizeLegibility;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    vertical-align: -0.125em;
    text-align: center;
}
[type=button], [type=reset], [type=submit], button {
    background-color: none !important;;
    background-image: none;
}
</style>
