<template>
                <div
                    class="fixed top-0 z-50 w-full flex flex-row items-center gap-2 xl:py-5 pt-2 px-5"
                    :style="`background-image: url('${baseImg}header.png')`"
                >
                    <div
                        data-drawer-target="logo-sidebar"
                        data-drawer-toggle="logo-sidebar"
                        aria-controls="logo-sidebar"
                        type="button"
                        class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg xl:hidden focus:outline-none focus:ring-2 focus:ring-[#003C24] dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                    >
                    </div>
                    <div class="flex xl:flex-row flex-col items-center w-full">
                        <div
                            class="flex flex-col items-left px-5 w-4/5"
                            @click="openHomePage()"
                        >
                            <img
                                src="./../../public/Vietnam Military History Museum (mobile).png"
                                alt="Your Company"
                                width="280"
                                class="xl:hidden block"
                            />
                            <img
                                src="./../../public/Vietnam Military History Museum (pc).png"
                                alt="Your Company"
                                width="450"
                                class="xl:block hidden"
                            />
                        </div>
                        <div class="xl:block hidden">
                            <Search></Search>
                        </div>
                        <Search
                            v-if="searchVisible"
                            @closeSearch="toggleSearch"
                        ></Search>
                    </div>
                    <div class="xl:hidden block">
                        <button @click="toggleSearch">
                            <img
                                src="./../../public/icon/icon search.svg"
                                alt="Your Company"
                                width="20"
                            />
                        </button>
                    </div>
                </div>


  </template>

  <script >
  import Search from './Search.vue';
  export default {
  name: 'Autoplay',
  components: {
    Search
  },
  data() {
    return {
      searchVisible: false,
    };
  },
  methods: {
    toggleSearch() {
      this.searchVisible = !this.searchVisible;
    },
  },

}
  </script>
