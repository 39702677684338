<template>
    <div class="min-w-screen flex justify-center items-center">
        <div class="relative space-y-2 xl:block hidden">
            <input
                type="text"
                id="search"
                v-model="searchTerm"
                placeholder="Từ khóa"
                class="w-full border border-gray-300 rounded bg-[#1A1A1A] text-[#fff] rounded-full "
                style="width: 300px"
                @input="changeKeyword()"/>
            <ul class="w-full rounded bg-white border border-gray-300 p-2 absolute" v-if="list">
                <div class="line-wobble" v-if="loading"></div>
                <div v-if="dataExhibit.length > 0">
                    <li
                        v-for="exhibit in dataExhibit"
                        :key="exhibit.exhibitId"
                        @click="artifactDetail(exhibit.exhibitId)"
                        class="cursor-pointer hover:bg-gray-100 p-1 font-sans">
                        {{ exhibit.exhibitTitle }}
                    </li>
                </div>
                <div class="text-[#bdc3c7]" v-else>
                    Không tìm thấy hiện vật
                </div>
            </ul>
        </div>
        <div class="relative space-y-2 xl:hidden block">
            <input
                type="text"
                id="search"
                v-model="searchTerm"
                placeholder="Từ khóa"
                class="w-full border border-gray-300 rounded bg-[#1A1A1A] text-[#fff] rounded-full "
                style="width: 300px;height: 30px;"
                @input="changeKeyword()"/>
            <ul class="w-full rounded bg-white border border-gray-300 p-2 absolute" v-if="list">
                <div class="line-wobble" v-if="loading"></div>
                <div v-if="dataExhibit.length > 0">
                    <li
                        v-for="exhibit in dataExhibit"
                        :key="exhibit.exhibitId"
                        @click="artifactDetail(exhibit.exhibitId)"
                        class="cursor-pointer hover:bg-gray-100 p-1 font-sans">
                        {{ exhibit.exhibitTitle }}
                    </li>
                </div>
                <div class="text-[#bdc3c7]" v-else>
                    Không tìm thấy hiện vật
                </div>
            </ul>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/config/url";
export default {
    data() {
        return {
            searchTerm: "",
            selectedExhibit: "",
            dataExhibit: [],
            list: false,
            loading: false,
            timeOut: null,
        };
    },

    created() {
        this.handleGetExhibit();
    },
    methods: {
        async handleGetExhibit() {
            try {
                const response = await axios.get(
                    `${BASE_URL}/api/exhibit/all`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );

                this.dataExhibit = response.data.data;
            } catch (error) {
                console.log("Response data:", error.response.data);
                console.log("Response status:", error.response.status);
                console.log("Response headers:", error.response.headers);
            }
        },
        async searchExhibit() {
            try {
                const response = await axios.get(
                    `${BASE_URL}/api/exhibit/no_descrip/all?query=${this.searchTerm}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );

                this.dataExhibit = response.data.data;
                this.loading = false;
                this.list = true;
            } catch (error) {
                console.log("Response data:", error.response.data);
                console.log("Response status:", error.response.status);
                console.log("Response headers:", error.response.headers);
            }
        },
        changeKeyword() {
            this.loading = true;
            if(this.searchTerm.length > 0) {
                if(this.timeOut) clearTimeout(this.timeOut);
                    this.timeOut = setTimeout(() => {
                        this.searchExhibit();
                    }, 500)
            } else {
                this.list = false;
            }
        },
        artifactDetail(val) {
            console.log("artifactDetail called with id:", val);

            const route = this.$router.resolve({
                name: "detail",
                params: {
                    id: val,
                },
            });
            window.open(route.href, "_parent");
        },
    },
};
</script>
<style>
.line-wobble {
  --uib-size: 100%;
  --uib-speed: 1.75s;
  --uib-color: rgb(29, 79, 50);
  --uib-line-weight: 5px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--uib-line-weight);
  width: var(--uib-size);
  border-radius: calc(var(--uib-line-weight) / 2);
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.line-wobble::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--uib-color);
  opacity: 0.1;
}

.line-wobble::after {
  content: '';
  height: 100%;
  width: 100%;
  border-radius: calc(var(--uib-line-weight) / 2);
  animation: wobble var(--uib-speed) ease-in-out infinite;
  transform: translateX(-95%);
  background-color: var(--uib-color);
}

@keyframes wobble {
  0%,
100% {
    transform: translateX(-95%);
  }

  50% {
    transform: translateX(95%);
  }
}
</style>
